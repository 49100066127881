body {
  margin: 0;
  padding: 0;
  font-family: 'microsoft yahei', 黑体, Helvetica, Arial, "\5b8b\4f53", sans-serif;
  background-color: #f0f0f0;
}

::-webkit-scrollbar {
  width: 11px;
  height: 5px;
}

::-webkit-scrollbar-track-piece {
  background-color: #f1f1f1;
  -webkit-border-radius: 11px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: #c5c5c5;
  -webkit-border-radius: 11px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 11px;
  background-color: #c5c5c5;
  -webkit-border-radius: 11px;
}

/*div{
	overflow: hidden;
}*/
.clearfix:after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: "";
}

.clearfix {
  zoom: 1;
}

.left {
  *display: inline;
  float: left;
}

.fl {
  float: left;
}

.right {
  *display: inline;
  float: right;
}

.fr {
  float: right;
}

.inline-block {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.public-area {
  width: 960px;
  margin: 0 auto;
}

.public-bg-color {
  background: linear-gradient(-45deg, #ff0040, #f22);
}

.public-logo {
  width: 61px;
  height: auto;
  vertical-align: middle;
  cursor: pointer;
}

.min-pc-width {
  min-width: 960px;
}

.public-nav {
  height: 80px;
  color: #fff;
  font-size: 0;
  line-height: 80px;
}

.public-nav-link {
  display: inline-block;
  margin-left: 20px;
  padding: 0 7px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  vertical-align: top;
}

.public-nav-link:hover {
  text-decoration: none;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

.public-nav-current {
  line-height: 74px;
  border-top: 3px solid #fff;
}

.public-footer {
  padding: 29px 0 24px 0;
  color: #b2b2b2;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  background-color: #f0f0f0;
}

.public-footer-link {
  color: #8b8a8a;
  text-decoration: none;
}

.public-color {
  color: #f37c5c;
}

.footer-bottom {
  position: fixed;
  bottom: 100px;
  width: 100%;
}

.userPage-top-bar {
  position: relative;
  height: 60px;
  font-size: 0;
  line-height: 60px;
}

.userPage-ktv-img {
  width: 206px;
  margin-left: 10px;
  vertical-align: middle;
}

.userPage-download-link {
  position: absolute;
  top: 0;
  right: 0;
  width: 122px;
  height: 112px;
  color: #fff;
  font-size: 16px;
  line-height: 135px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 2px 1px #ae2122;
  background: url(//cb.cdn.changbaimg.com/images/officialwebsite/usercenter/download_bg.png) no-repeat center center;
}

.empty-div {
  padding-top: 100px;
  padding-bottom: 120px;
  text-align: center;
}

.empty-div p {
  margin: 0;
  margin-top: 20px;
  padding: 0;
  color: #b2b2b2;
  font-size: 15px;
}

button {
  cursor: pointer;
}

.magin-top-30 {
  margin-top: 30px;
}